<template>
  <div
    class="modal modal-drawer-list-scroll fade fixed-end"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Select Topics</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- /.modal-header -->

        <div class="modal-body">
          <label class="visually-hidden">Search</label>
          <c-input placeholder="Search" v-model="search"/>
        </div>
        <div class="scroll-container">
          <ul class="list-data list-data-topic-select no-links">
            <template v-for="topic in filtered">
              <li :key="`topic-${topic.id}`">
                <div class="row">
                  <div class="col text-truncate">
                    <p class="text-truncate">
                      {{ topic.name }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <button
                      v-if="value.includes(topic.id)"
                      type="button"
                      class="btn btn-sm btn-primary btn-add"
                      @click="remove(topic)"
                    >
                      Added
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-sm btn-outline-primary btn-add"
                      @click="add(topic)"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </li>
              <li
                class="sub"
                v-for="child in topic.children"
                :key="`topic-${topic.id}-child-${child.id}`"
              >
                <div class="row">
                  <div class="col text-truncate">
                    <p class="text-muted text-truncate">{{ child.name }}</p>
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary btn-add"
                      v-if="value.includes(child.id)"
                      @click="remove(child)"
                    >
                      Added
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-sm btn-outline-primary btn-add"
                      @click="add(child)"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div class="modal-footer border-top">
          <button
            type="button"
            class="btn btn-outline-info"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'
import { call, get } from 'vuex-pathify'
import Fuse from 'fuse.js'

export default {
  extends: CModal,

  props: {
    value: {
      required: true,
      type: Array
    },
    professions: {
      type: Array
    },
  },

  data () {
    return {
      search: null,
      fuse: null
    }
  },

  computed: {
    topics: get('lists/topics/topics'),

    filtered () {
      if (this.search) {
        const parents = new Fuse(this.topics, {
          keys: ['name', 'children.name'],
          threshold: 0.25,
          shouldSort: false
        })

        return parents.search(this.search).map(({ item }) => {
          const children = new Fuse(item.children, {
            keys: ['name'],
            threshold: 0.25,
            shouldSort: false
          })

          return {
            ...item,
            children: children.search(this.search).map(({ item }) => item)
          }
        })
      }

      return this.topics
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
      if (this.topics.length === 0) {
        await this.getTopics({ params: { professions: this.professions } })
      }
    })
  },

  watch: {
    professions: {
      immediate: true,
      handler (professions) {
        this.getTopics({ params: { professions }, fresh: true })
      }
    },
  },

  methods: {
    getTopics: call('lists/topics/getTopics'),

    add (topic) {
      this.$emit('input', [...this.value, topic.id])
    },

    remove (topic) {
      const topics = [...this.value]

      const index = topics.indexOf(topic.id)

      if (index === -1) {
        return
      }

      topics.splice(index, 1)

      this.$emit('input', topics)
    }
  }
}
</script>
