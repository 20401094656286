<template>
  <div
    class="modal modal-drawer-list-scroll fade fixed-end"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Select Professions</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- /.modal-header -->

        <div class="modal-body">
          <label class="visually-hidden">Search</label>
          <c-input placeholder="Search" v-model="search"/>
        </div>

        <div class="scroll-container">
          <ul class="list-data list-data-topic-select no-links">
            <li v-for="profession in filtered" :key="`profession-${profession.id}`">
              <div class="row">
                <div class="col text-truncate">
                  <p class="text-truncate">
                    {{ profession.name }}
                  </p>
                </div>
                <div class="col-auto">
                  <button
                    v-if="value.includes(profession.id)"
                    type="button"
                    class="btn btn-sm btn-primary btn-add"
                    @click="remove(profession)"
                  >
                    Added
                  </button>

                  <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-outline-primary btn-add"
                    @click="add(profession)"
                  >
                    Add
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="modal-footer border-top">
          <button
            type="button"
            class="btn btn-outline-info"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'
import { call, get } from 'vuex-pathify'

export default {
  extends: CModal,

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      search: null,
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
      if (this.professions.length === 0) {
        await this.getProfessions()
      }
    })
  },

  computed: {
    ...get('lists/professions', ['professions']),

    filtered () {
      if (!this.search) {
        return this.professions
      }

      return this.professions.filter(profession => {
        return profession.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },

  methods: {
    ...call('lists/professions', ['getProfessions']),

    add (profession) {
      this.$emit('input', [...this.value, profession.id])
    },

    remove (profession) {
      const professions = [...this.value]

      const index = professions.indexOf(profession.id)

      if (index === -1) {
        return
      }

      professions.splice(index, 1)

      this.$emit('input', professions)
    }
  },
}
</script>
