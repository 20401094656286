import { quillEditor } from 'vue-quill-editor'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: String
  },

  computed: {
    options () {
      return {
        theme: 'snow',
        placeholder: null,
        formats: [
          'bold',
          'italic',
          'underline',
          'header',
          'list',
        ],
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            [
              'bold',
              'italic',
              'underline',
            ],
            [
              { list: 'ordered' },
              { list: 'bullet' },
            ],
            ['clean']
          ]
        }
      }
    }
  },

  render (h) {
    return h(quillEditor, {
      props: {
        options: this.options,
        value: this.value
      },
      style: {
        minHeight: '280px'
      },
      class: [this.invalidClass],
      on: {
        change: ({ html }) => {
          this.$emit('input', html)
        }
      }
    })
  }
}
