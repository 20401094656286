<template>
  <div
    class="modal modal-drawer-list-scroll fade fixed-end"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Select Primary Fields</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- /.modal-header -->

        <div class="modal-body">
          <label class="visually-hidden">Search</label>
          <c-input placeholder="Search" v-model="search"/>
        </div>

        <div class="scroll-container">
          <ul class="list-data list-data-topic-select no-links">
            <li v-for="primaryField in filtered" :key="`primary-field-${primaryField.id}`">
              <div class="row">
                <div class="col text-truncate">
                  <p class="text-truncate">
                    {{ primaryField.name }}
                  </p>
                </div>
                <div class="col-auto">
                  <button
                    v-if="value.includes(primaryField.id)"
                    type="button"
                    class="btn btn-sm btn-primary btn-add"
                    @click="remove(primaryField)"
                  >
                    Added
                  </button>

                  <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-outline-primary btn-add"
                    @click="add(primaryField)"
                  >
                    Add
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="modal-footer border-top">
          <button
            type="button"
            class="btn btn-outline-info"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'
import { call, get } from 'vuex-pathify'

export default {
  extends: CModal,

  props: {
    value: {
      type: Array,
      required: true,
    },
    professions: {
      type: Array
    },
  },

  data () {
    return {
      search: null,
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
      if (this.primaryFields.length === 0) {
        await this.getPrimaryFields({ params: { professions: this.professions } })
      }
    })
  },

  watch: {
    professions: {
      immediate: true,
      handler (professions) {
        this.getPrimaryFields({ params: { professions }, fresh: true })
      }
    },
  },

  computed: {
    ...get('lists/primaryFields', ['primaryFields']),

    filtered () {
      if (!this.search) {
        return this.primaryFields
      }

      return this.primaryFields.filter(primaryField => {
        return primaryField.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },

  methods: {
    ...call('lists/primaryFields', ['getPrimaryFields']),

    add (primaryField) {
      this.$emit('input', [...this.value, primaryField.id])
    },

    remove (primaryField) {
      const primaryFields = [...this.value]

      const index = primaryFields.indexOf(primaryField.id)

      if (index === -1) {
        return
      }

      primaryFields.splice(index, 1)

      this.$emit('input', primaryFields)
    }
  },
}
</script>
